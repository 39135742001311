import request from '@/utils/request'

export function getGiftHistoryList(data) {
  return request({
    url: 'marketing-web-api/admin/activity/winning/table/pageList',
    method: 'POST',
    data,
  })
}

export function updateGiftHistoryItem(data) {
  return request({
    url: 'marketing-web-api/admin/activity/winning/table/save',
    method: 'POST',
    data,
  })
}

export function getGiftHistoryStatusList(data) {
  return request({
    url: 'marketing-web-api/admin/activity/winning/table/statusList',
    method: 'POST',
    data,
  })
}
