<template>
  <div class="gift-release-history">
    <div class="search-bar">
      <span class="label">用户：</span>
      <el-input
        size="small"
        placeholder="请输入用户ID/名称"
        class="input"
        v-model="search.userIdOrName"
      ></el-input>

      <span class="label">手机号：</span>
      <el-input
        size="small"
        placeholder="请输入手机号"
        class="input"
        v-model="search.userPhone"
      ></el-input>

      <span class="label">礼品发放状态：</span>
      <el-select
        v-model="search.prizeSendStatus"
        placeholder="请选择状态"
        size="small"
        class="select"
      >
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <span class="label">礼品：</span>
      <el-input
        v-model="search.prizeNoOrName"
        size="small"
        placeholder="请输入礼品ID/名称"
        class="input"
      ></el-input>

      <span class="label">礼品类型：</span>
      <el-select
        v-model="search.prizeType"
        placeholder="请选择礼品类型"
        size="small"
        class="select"
      >
        <el-option label="全部" value=""> </el-option>
        <el-option label="实物" :value="0"> </el-option>
        <el-option label="卡券" :value="1"> </el-option>
      </el-select>

      <span class="label">参加活动：</span>
      <el-input
        v-model="search.activityNoOrName"
        size="small"
        placeholder="请输入活动ID/名称"
        class="input"
      ></el-input>

      <span class="label">活动形式：</span>
      <el-select
        v-model="search.activityType"
        placeholder="请选择活动形式"
        size="small"
        class="select"
      >
        <el-option label="全部" value=""> </el-option>
        <el-option label="满赠" :value="0"> </el-option>
        <el-option label="盲盒" :value="1"> </el-option>
      </el-select>

      <span class="label">活动参与时间：</span>
      <el-date-picker
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        size="small"
        class="date-picker"
        v-model="createTimeArr"
      >
      </el-date-picker>

      <span class="label">礼品生效时间：</span>
      <el-date-picker
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        size="small"
        class="date-picker"
        v-model="effectiveTimeArr"
      >
      </el-date-picker>

      <el-button type="primary" size="small" class="btn" icon="el-icon-search" @click="searchList">
        查询
      </el-button>
      <el-button size="small" class="btn" icon="el-icon-refresh-left" @click="resetSearch">
        重置
      </el-button>
    </div>

    <GiveList :table-data="tableData" @refresh="getList" />

    <div class="footer">
      <el-pagination
        background
        layout="total,prev,pager,next,sizes"
        :total="total"
        :page-sizes="[10, 50, 100, 200]"
        @size-change="pageSizeChange"
        @current-change="pageChange"
        :current-page="pageNumber"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getGiftHistoryList, getGiftHistoryStatusList } from '@/api/giftHistory'

export default {
  data() {
    return {
      tableData: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      search: {
        userIdOrName: '',
        userPhone: '',
        prizeSendStatus: '',
        prizeType: '',
        prizeNoOrName: '',
        activityNoOrName: '',
        activityType: '',
      },
      statusOptions: [],
      effectiveTimeArr: [],
      createTimeArr: [],
    }
  },
  computed: {
    searchCondition() {
      const obj = { ...this.search }

      if (this.effectiveTimeArr.length > 1) {
        obj['effectiveTimeStart'] = this.effectiveTimeArr[0]
        obj['effectiveTimeEnd'] = this.effectiveTimeArr[1]
      }

      if (this.createTimeArr.length > 1) {
        obj['createTimeStart'] = this.createTimeArr[0]
        obj['createTimeEnd'] = this.createTimeArr[1]
      }

      return {
        data: obj,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }
    },
  },
  mounted() {
    this.getList()
    this.getStatusList()
  },
  methods: {
    resetSearch() {
      Object.keys(this.search).forEach((el) => {
        this.search[el] = Array.isArray(this.search[el]) ? [] : ''
      })

      this.createTimeArr = []
      this.effectiveTimeArr = []
      this.pageNumber = 1
      this.getList()
    },
    getList() {
      getGiftHistoryList(this.searchCondition)
        .then((res) => {
          const { content, total } = res.data || {}
          this.tableData = content || []
          this.total = Number(total || '0')
        })
        .catch((err) => console.error(err))
    },
    pageSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    pageChange(val) {
      this.pageNumber = val
      this.getList()
    },
    searchList() {
      this.pageNumber = 1
      this.getList()
    },
    getStatusList() {
      getGiftHistoryStatusList({ data: {} })
        .then((res) => {
          this.statusOptions = [{ label: '全部', value: '' }]
          Object.keys(res.data || {}).forEach((key) => {
            this.statusOptions.push({ label: res.data[key], value: key })
          })
        })
        .catch((err) => console.log(err))
    },
  },
}
</script>

<style lang="scss" scoped>
.gift-release-history {
  background-color: white;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;

  .search-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > :nth-child(n) {
      margin-top: 20px;
    }

    .label {
      font-size: 14px;
      margin-left: 15px;
    }

    .select {
      width: 200px;
      margin-right: 10px;
    }

    .select-max {
      width: 430px;
      margin-right: 10px;
    }

    .input {
      width: 220px;
    }

    .date-picker {
      width: 360px;
    }

    .btn {
      margin-left: 15px;
    }
  }

  .table-ex {
    margin-top: 20px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 0 0;
  }

  .dialog-footer {
    text-align: right;
  }
}
</style>
